

<mat-card>


  <mat-card-title>

    <div cclass="section-button">

      <div cclass="section-icon">

        <mat-icon>
          {{clusterNode.iconName?clusterNode.iconName:'corporate_fare'}}
        </mat-icon>
      </div>

      <div cclass="section-index">
        Section {{sectionIndex}}
      </div>

      <div cclass="section-title">
        <h3>
          {{clusterNode.cluster.value.name}}
        </h3>
        <span style="display: none">{{clusterNode.cluster._self.toString}}</span>
      </div>

      <div cclass="section-completed">
        <completed-tick
          [completed]="clusterNode.cluster.value.completed"
        ></completed-tick>
      </div>
    </div>


  </mat-card-title>

  <mat-card-content>

    <div *ngFor="let child of clusterNode.children" cclass="sub-section-row" >

      <button cclass="sub-section" mat-raised-button color="primary"
              (click)="onEvaluateChild( child )">

        <div cclass="sub-section">
          <mat-icon cclass="sub-section-icon">
            {{child.iconName?child.iconName:'corporate_fare'}}
          </mat-icon>

          <span cclass="sub-section-title">
          {{child.cluster.value.name}}
            <span style="display: none">{{child.cluster._self.toString}}</span>
        </span>

          <mat-icon cclass="sub-section-completed" aria-hidden="false" aria-label="Answer questions"
                    *ngIf="child.cluster.value.completed"
          >
            done
          </mat-icon>

        </div>

      </button>

      <div cclass="sub-section-edit">

        <button cclass="sub-section-edit" mat-raised-button
                *ngIf="canEditClusters"
                (click)="onEditChild(child)"
                [disabled]="evaluationState==evaluationStates.submitting||evaluationState==evaluationStates.submitted"
        >
          <mat-icon aria-hidden="false" aria-label="Edit"
          >
            edit
          </mat-icon>
        </button>

      </div>




    </div>


    <div style="margin-top: 8px;"
         *ngIf="canEditClusters"
    >

      <button cclass="add-sub-section" class="sub-section add-sub-section" mat-raised-button
              [disabled]="evaluationState==evaluationStates.submitting||evaluationState==evaluationStates.submitted"
              (click)="onAddSubSection()"
      >

        <div cclass="add-sub-section">

          <mat-icon cclass="add-sub-section-icon" aria-hidden="false" aria-label="Add">add</mat-icon>
          <span cclass="add-sub-section-title">Add Sub-Section</span>
          <span cclass="add-sub-section-filler"></span>

        </div>

      </button>

    </div>

  </mat-card-content>



  <!--  <mat-card-actions align="end">-->
<!--  </mat-card-actions>-->


</mat-card>

