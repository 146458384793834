import {ECGReferenceType} from "./CGReferenceType";
import {ValueObject} from "../../ValueObject";
import {MMBaseException} from "../../../util/MMBaseException";


export type AppTypedReferenceString = string;


export interface IAppTypedReference {

  type: ECGReferenceType;
  id: string;
}

export class AppTypedReference extends ValueObject<IAppTypedReference>{

  public toString: AppTypedReferenceString;

  static buildValue( reference: AppTypedReferenceString ): IAppTypedReference {

    const indexOfColon = reference.indexOf( ":");

    if( -1 === indexOfColon ) {

      return {
        type: ECGReferenceType.none,
        id: reference,
      }
    }

    const referenceType = reference.substring( 0, indexOfColon );
    const id = reference.substring( indexOfColon + 1 );

    if( 0 === referenceType.length ) {
      throw MMBaseException.build( "CGTypedReference", "0 === referenceType.length",  {
        reference: reference
      } );
    }

    if( 0 === id.length ) {
      throw MMBaseException.build( "CGTypedReference", "0 === id.length",  {
        reference: reference
      } );
    }

    const type = referenceType as ECGReferenceType;
    return {
      type,
      id
    };
  }

  static build( reference: AppTypedReferenceString ): AppTypedReference {

    const value = AppTypedReference.buildValue( reference );
    return new AppTypedReference( value );
  }

  public equals( other: AppTypedReference ): boolean {

    if( !other ) {
      return false;
    }

    if( other === this ) {

      return true;
    }

    if( other.value.type === this.value.type &&
      other.value.id === this.value.id) {
      return true;
    }
    return false;
  }

  protected onSetValue(value: IAppTypedReference | null) {
    this.toString = null;

    if( !this.value ) {

      return;
    }

    this.toString = `${value.type}:${value.id}`;

  }

  constructor( value: IAppTypedReference ) {

    super( value );
    if( value ) {
      this.value = value;
    }

    // if( ECGReferenceType.QuestionBoolean === value.type ) {
    //
    //   this.isBoolean = true;
    // } else if( ECGReferenceType.QuestionEnum === value.type ) {
    //
    //   this.isEnum = true;
    // } else if( ECGReferenceType.QuestionFloat === value.type ) {
    //
    //   this.isFloat = true;
    // } else if( ECGReferenceType.QuestionInteger === value.type ) {
    //
    //   this.isInteger = true;
    // } else if( ECGReferenceType.QuestionLine === value.type ) {
    //
    //   this.isLine = true;
    // } else if( ECGReferenceType.QuestionMeasurement === value.type ) {
    //
    //   this.isMeasurement = true;
    // } else if( ECGReferenceType.QuestionPhoto === value.type ) {
    //
    //   this.isPhoto = true;
    // } else if( ECGReferenceType.QuestionTernary === value.type ) {
    //
    //   this.isTernary = true;
    // } else if( ECGReferenceType.QuestionText === value.type ) {
    //
    //   this.isText = true;
    // }

  }


}
