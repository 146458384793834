
import {AppTypedReference, AppTypedReferenceString, IAppTypedReference} from "../cg/core/AppTypedReference";
import {AppParentChild, IAppParentChild} from "./AppParentChild";


export class AppParentChildSet {

  values: AppParentChild[] = [];
  valuesById: {[id: AppTypedReferenceString]: AppParentChild} = {};


  private _getParent( child: AppParentChild ): AppParentChild|null {

    if( child.parent === child.child ) {

      return null;
    }

    for( const candidate of this.values ) {

      if( child._self.equals( candidate.child ) ) {

        return candidate;
      }
    }

    return null;
  }

  public findParentOfChild( child: AppTypedReference ): AppParentChild|null {

    for( const candidate of this.values ) {

      if( child.equals( candidate.child ) ) {

        return candidate;
      }
    }
  }

  getPath( child: AppTypedReference ): AppTypedReference[] {

    let current: AppParentChild|null = this.findParentOfChild( child );

    if( null == current ) {

      return [];
    }

    const answer: AppTypedReference[] = [current.child];

    do {

      answer.push( current.parent );
      current = this._getParent( current );

    } while ( null != current );

    return answer.reverse();
  }





  getChildren( parent: AppTypedReference ): AppTypedReference[] {

    const answer = [];

    for( const candidate of this.values ) {
      if( parent.equals( candidate.parent ) && !candidate.value.trashed ) {

        answer.push( candidate.child );
      }
    }

    return answer;
  }

  getParentChild(parent: AppTypedReference, child: AppTypedReference ): AppParentChild {

    for( const candidate of this.values ) {
      if( parent.equals( candidate.parent ) && child.equals( candidate.child ) ) {

        return candidate;
      }
    }

    return null;
  }

  constructor( value: { [key: AppTypedReferenceString]: IAppParentChild; } ) {

    for( const key of Object.keys( value )) {

      const objectValue: IAppParentChild = value[key];
      const parentChild = new AppParentChild( objectValue );
      this.values.push(parentChild);
      this.valuesById[key] = parentChild;
    }
  }

}
