import {ValueObject} from "../javascript.lib.mojo-base/model/ValueObject";
import {EInternationalStandard} from "../javascript.lib.mojo-base/model/InternationalStandard";
import {IType} from "../javascript.lib.mojo-base/model/AppQuestion";


export interface IReportFeature {

  identifier: string;

  nocoDbReportFeatureId?: number;


  inclusionCriteria?: {

    ifLessThanFullScore: {
      questionKeys: string[];
    }
  }


  // version 2
  feature?: string;
  description?: string;
  images?: string[];

  AdaStandard?: string;
  BsStandard?: string;
  ISOStandard?: string;
  EnStandard?: string;

  capexLight: string[];
  capexIntensive: string[];

  complianceScoring: {[key: string]: any};
}

export class ReportFeature extends ValueObject<IReportFeature>{


  protected onSetValue(value: IReportFeature | null) {
  }



  constructor( value: IReportFeature ) {

    super(value);
  }

}


