import {AppQuestion, IDependantDescriptor, IAppQuestion} from '../AppQuestion';
import {IScoredQuestion} from "../ScoredQuestion";
import {LoggerFactory} from "../../log/LoggerFactory";
import {QuestionSet} from "../QuestionSet";
import {AppPageDefinition, IAppPageDefinition} from "../app/AppPageDefinition";



export interface IAppAspectDefinition {

  name: string;
  // firebaseAnswersRoot: string;
  firebaseAspectId: string;
  questions: IAppQuestion[];
  sections: {
    evaluation: IAppPageDefinition[];
    portal: IAppPageDefinition[];
  };
  evaluation?: {
    homePageIconPath: string;
  }
  portal?: {
    navigationMenu: {
      iconImgBase: string
    }
  };
  uuid: string;
  spreadsheetRow?: number;
}

export class AppAspectDefinition {

  private _log = LoggerFactory.build( 'AppAspectDefinition' );


  public questionSet: QuestionSet;

  /**
   * @deprecated use `questionSet`
   */
  questions: AppQuestion[] = [];
  /**
   * @deprecated use `questionSet`
   */
  questionsByKey: {[key: string]: AppQuestion} = {};


  evaluationSections: AppPageDefinition[] = [];
  portalSections: AppPageDefinition[] = [];
  homePageIconPath = 'assets/icons/general_hotel_icon.purple.svg';

  static ARRAY = {

    toQuestionSet: ( aspects: AppAspectDefinition[] ) => {

      const value: IAppQuestion[] = [];

      for( const aspect of aspects ) {

        for( const question of aspect.questions ) {

          value.push( question.value );
        }
      }

      return new QuestionSet( value );

    },

    /**
     * @deprecated use EvaluationSection.ARRAY.toQuestionSet(), instance is available on the environment
     * @param aspects
     */
    toMapOfKeyToQuestionReference: ( aspects: AppAspectDefinition[] ) => {

      const answer: { [key: string]: AppQuestion; } = {};

      for( const aspect of aspects ) {

        for( const question of aspect.questions ) {

          answer[question.value.key] = question;
        }
      }

      return answer;
    },

    /**
     * @deprecated use EvaluationSection.ARRAY.toQuestionSet(), instance is available on the environment
     * @param aspects
     */
    toArrayOfQuestions: ( aspects: AppAspectDefinition[] ): AppQuestion[] => {

      const answer: AppQuestion[] = [];

      for( const aspect of aspects ) {

        for( const question of aspect.questions ) {

          answer.push( question );
        }
      }

      return answer;
    }

  }

  public sectionQuestionsToJson( sectionQuestions: AppPageDefinition[] ): string  {

    let answer = '[\n';
    for( const section of sectionQuestions ) {
      answer += '{\n'
      answer += `headerText: '${section.value.title}',\n`
      answer += `questionKeys: [\n`
      for( const key of section.value.questionKeys ) {


        answer += `'${key}', // ${this.questionSet.questionByKey[key].value.label}\n`
      }
      answer += `]\n`
      answer += '},\n'
    }
    answer += ']\n';

    return answer;

  }



  private _validateSections(sections: AppPageDefinition[], name: string ): number {

    let issues = 0;
    const questionsByKey = Object.assign( {}, this.questionSet.questionByKey );
    for( const section of sections ) {

      if( section.value.dependantDescriptor ) {
        const dependent = this.questionSet.questionByKey[section.value.dependantDescriptor.questionKey];
        if( !dependent ) {

          issues++;
          this._log.error( ` section '${section.value.title}' has dependant '${section.value.dependantDescriptor.questionKey}' not found in questions` );
        }
      }

        for( const questionKey of section.value.questionKeys ) {

        if( questionsByKey[questionKey] ) {
          delete questionsByKey[questionKey];
        } else {

          issues++;
          this._log.error( `questionKey '${questionKey}' in section '${section.value.title}' in '${name}' not found in questions` );
        }
      }
    }

    for( const remainingKey in Object.keys( questionsByKey ) ) {

      issues++;
      this._log.error( `remainingKey '${remainingKey}' not found in any sections in '${name}'` );
    }

    return issues;
  }

  private _validateDependents(): number {

    let issues = 0;


    for( const question of this.questions ) {

      const dependant: IDependantDescriptor = question.value.dependant;
      if( dependant ) {

        if( !this.questionSet.questionByKey[dependant.questionKey] ) {

          issues++;
          this._log.error( `question with key '${question.value.key}' has dependent with key '${dependant.questionKey}' that does not exist` );
        }
      }
    }

    return issues;
  }



  validate() {

    let issues = this._validateSections( this.evaluationSections, 'evaluationSections' );
    issues += this._validateDependents();
    console.log( `issues: ${issues}` );

  }

  private _init() {

    this.questionSet = new QuestionSet( this.value.questions );

    if( this.value.evaluation && this.value.evaluation.homePageIconPath ) {

      this.homePageIconPath = this.value.evaluation.homePageIconPath;
    }

    // build up all the questions ...
    for( const value of this.value.questions ) {

      const q = new AppQuestion( value );
      this.questions.push( q );
      this.questionsByKey[ q.value.key ] = q;
    }

    // build up the sections ...
    for( const section of this.value.sections.evaluation ) {

      const sectionQuestions = new AppPageDefinition( section );
      sectionQuestions.initQuestionFields( this.questionSet.questionByKey );
      this.evaluationSections.push( sectionQuestions );
    }
    for( const section of this.value.sections.portal ) {

      const sectionQuestions = new AppPageDefinition( section );
      sectionQuestions.initQuestionFields( this.questionSet.questionByKey );
      this.portalSections.push( sectionQuestions );
    }
  }

  private _removePhotoQuestions( otherValue: IAppAspectDefinition): void {


    {
      const noPhotoQuestions: IScoredQuestion[] = [];
      for( const question of this.questions ) {

        if( !question.isPhoto ) {

          noPhotoQuestions.push( question.value as IScoredQuestion );
        }
      }
      otherValue.questions = noPhotoQuestions;
    }

    {
      const noPhotoEvaluationSections: IAppPageDefinition[] = [];
      let index = 0;
      for( const evaluationSection of this.evaluationSections ) {

        if( 1 === evaluationSection.questions.length && evaluationSection.questions[0].isPhoto ) {

          // skip
          this._log.debug( `not including this.evaluationSections[${index}]`, evaluationSection );

        } else {

          noPhotoEvaluationSections.push( evaluationSection.value );
        }

        index++;
      }

      otherValue.sections.evaluation = noPhotoEvaluationSections;
    }

  }

  public cloneWithoutPhotos() {

    const json = JSON.stringify( this.value );
    const cloneValue: IAppAspectDefinition = JSON.parse( json );
    this._removePhotoQuestions( cloneValue );
    return new AppAspectDefinition( cloneValue );
  }

  constructor( public value: IAppAspectDefinition ) {

    this._init();
  }

}
