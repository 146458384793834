import {IMMValueObject, FirebaseValueObject} from "../../firebase/realtime-database/FirebaseValueObject";
import {AppTypedReference, IAppTypedReference} from "../cg/core/AppTypedReference";
import {CGIdentifierGenerator} from "../cg/util/CGIdentifierGenerator";
import {ECGReferenceType} from "../cg/core/CGReferenceType";
import {AppCluster} from "./AppCluster";
import {FirebaseMetaData} from "../../firebase/realtime-database/FirebaseMetaData";


export interface IAppParentChild extends IMMValueObject {

  parent: IAppTypedReference;
  child: IAppTypedReference;
  trashed: boolean;
}


export class AppParentChild extends FirebaseValueObject<IAppParentChild>{

  public static readonly TYPE = ECGReferenceType.parent_child;

  parent: AppTypedReference;
  child: AppTypedReference;


  public static buildNew( parent: AppCluster, child: AppCluster ): AppParentChild {

    const answerId = CGIdentifierGenerator.generateId();

    const answerValue: IAppParentChild = {
      _self: {
        id: answerId,
        type: AppParentChild.TYPE
      },
      _meta: FirebaseMetaData.buildMeta(),
      parent: parent.value._self,
      child: child.value._self,
      trashed: false,
    }

    return new AppParentChild( answerValue );
  }

  protected onSetValue(value: IAppParentChild | null) {

    this.parent = null;
    this.child = null;

    super.onSetValue( value );

    if( value ) {

      this.parent = new AppTypedReference( value.parent );
      this.child = new AppTypedReference( value.child );
    }

  }


}
