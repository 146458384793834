

export enum EProductType {
  banking = "banking",
  brandHome = "brandHome",
  facilities = "facilities",
  foodAndBeverage = "food-and-beverage",
  manufacturing = "manufacturing",
  manufacturingCampus = "manufacturing-campus",
  publicRealm = "public-realm",
  retail = "retail",
}

