


export class BrowserAppEvaluationToolInfo {

  public version = '2024.1.4.2';

  constructor() {
  }

}
