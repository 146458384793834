import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {AppQuestion} from "../../../javascript.lib.mojo-base/model/AppQuestion";
import {ValueHelper} from "../../../javascript.lib.mojo-base/util/ValueHelper";
import {Subscription} from "rxjs";
import {AppAnswer} from "../../../javascript.lib.mojo-base/model/AppAnswer";
import {LoggerFactory} from "../../../javascript.lib.mojo-base/log/LoggerFactory";

/**
 * Generated class for the QuestionCentimetersComponent component.
 *
 * See https://angular.io/docs/ts/latest/api/core/index/ComponentMetadata-class.html
 * for more info on Angular Components.
 */
@Component({
  selector: 'question-centimeters',
  templateUrl: 'question-centimeters.html',
  styleUrls: ['question-centimeters.scss']
})
export class QuestionCentimetersComponent implements OnInit, OnDestroy {

  private _log = LoggerFactory.build( 'QuestionCentimetersComponent' );


  private _answer: AppAnswer;

  @Input() set answer(questionAnswer: AppAnswer) {

    this._answer = questionAnswer;
  };

  get answer() {

    return this._answer;
  }


  private _dependantSubscription: Subscription|null = null;

  @Input() required: boolean = true;


  @Input() set disabled(value: boolean) {

    if( value ) {

      this.formControl.disable();
      // this.formControl.setValue('');
      // this.questionAnswer.answer.value.value = null;
    } else {

      this.formControl.enable();
    }
  }

  @Input() public formControl: FormControl
    = new FormControl('', []);


  hasIssues(): boolean {

    if( !this.required ) {

      return false;
    }

    if( this.formControl.disabled ) {

      return false;
    }

    if( null === this.formControl.errors ) {

      return false;
    } else {

      this.formControl.markAsTouched();
      this.formControl.markAsDirty();
    }

    return true;

  }


  set model( value: string ) {


    const numberValue = ValueHelper.valueToInteger( value )

    this._log.debug('value', value);
    if( null === numberValue ) {


      this.answer.answer.value.value = null;
    } else {

      this.answer.answer.value.value = numberValue;
    }

    // hack to trigger any subjects the may have subscribed to updates ...
    this.answer.answer.value = this.answer.answer.value;

  }

  get model() {

    return this.answer.answer.value.value;
  }


  ngOnInit(): void {



    if( this.answer.question.value.type !== AppQuestion.TYPE_CM_MEASUREMENT ) {

      this._log.error('this.questionAnswer.question.value.type !== QuestionReference.TYPE_CM_MEASUREMENT');

    }

    this._log.debug('this.questionAnswer.answer', this.answer.answer);
    {
      const validators = [

        Validators.min(this.answer.question.value.type2.typeCmMeasurement.minValue),
        Validators.max(this.answer.question.value.type2.typeCmMeasurement.maxValue),
      ];

      if( this.required ) {

        validators.push( Validators.required );
      }
      this.formControl.setValidators( validators );

    }

    const dependantAnswer = this.answer.answer.dependant;

    if( dependantAnswer ) {

      this._dependantSubscription = dependantAnswer.getSubject().subscribe( (dependant: AppAnswer ) => {

        const isTruthy = dependant.isTruthy( this.answer.question.value.dependant );
        this._log.debug('isTruthy', isTruthy);
        this.disabled = !isTruthy;
      } );
    }
  }

  ngOnDestroy(): void {

    if( this._dependantSubscription ) {

      this._dependantSubscription.unsubscribe();
      this._dependantSubscription = null;
    }
  }

  constructor() {
  }


}
